/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates classes
import classnames from "classnames";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardImg,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Badge
} from "reactstrap";


class MarketAppSample extends React.Component {
  state = {};
  render() {
    return (
      <>
        <section className="section section-lg section-shaped">
          <div className="shape shape-style-1 shape-default">
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
          </div>
          <Container className="py-md">
            <Row className="row-grid justify-content-between align-items-center">
              <Col lg="6">
                <h3 className="display-3 text-white ">
                  Well Designed App{" "}
                  <span className="text-white">completed with examples</span>
                </h3>
                <p className="lead text-white">
                  The shopping app comes with four tabs to help customer
                  get what they want faster. Customer can put what they want in the basket
                  simplly by tapping on the "add to cart" button, and order at once.
                  More importantly, the saler/store administrator can add or update their product
                  in the same app as long as they log in with admin account.
                </p>

                <ul className="list-unstyled mt-5 text-white">
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="Neutral"
                            >
                              <i className="ni ni-settings-gear-65" />
                            </Badge>
                          </div>
                          <div>
                            <h5 className="mb-0 text-white">
                              React Native
                            </h5>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="Neutral"
                            >
                              <i className="ni ni-chat-round" />
                            </Badge>
                          </div>
                          <div>
                            <h5 className="mb-0 text-white">RestAPI/Firebase</h5>
                          </div>
                        </div>
                      </li>
                      <li className="py-2 ">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="Neutral"
                            >
                              <i className="ni ni-satisfied" />
                            </Badge>
                          </div>
                          <div>
                            <h5 className="mb-0 text-white">
                              Redux and AsyncStorage   
                            </h5>
                          </div>
                        </div>
                      </li>
                    </ul>
                <div className="btn-wrapper">

                  <Button
                    className="btn-white"
                    color="default"
                    
                    href="/#contact-me"
                  >
                    Contact to learn more...
                  </Button>
                </div>
              </Col>
              <Col className="mb-lg-auto" lg="4"  md = '10' sm ='10' xs = '10'>
                <div className="transform-perspective-right">
                  <Card className="bg-secondary shadow border-0" >
                  
                    <CardImg
                      alt="..."
                      src={require("assets/img/brand/marketApp.gif")}
                      top
                    />

                 
                  </Card>
                </div>
              </Col>
            </Row>
          </Container>
          {/* SVG separator */}
          <div className="separator separator-bottom separator-skew">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon className="fill-white" points="2560 0 2560 100 0 100" />
            </svg>
          </div>
        </section>
      </>
    );
  }
}

export default MarketAppSample;
