
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/font-awesome/css/font-awesome.min.css";
import "assets/scss/argon-design-system-react.scss?v1.1.0";

import Index from "views/Index.js";
import Sample from "views/front/Sample.js";
import Profile from "views/front/Profile.js";
import Register from "views/front/Register.js";
import Thankyou from "views/front/Thankyou.js";
import Privacy from "views/front/Privacy.js";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
    <Route path="/" exact render={props => <Index {...props} />} />

    <Route path="/sample-page" component={Sample} />
    <Route path="/thankyou-page" component={Thankyou} />
    <Route path="/register-page" component={Register} />
    <Route path="/Privacy-page" component={Privacy} />
    <Route path="/profile-page" component={Profile} />
    <Redirect to="/" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);