/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import CardsFooter from "components/Footers/CardsFooter.js";

// index page sections
import {

  Container,
  Row,
  Col
} from "reactstrap";
import Background from '../../assets/img/brand/background.jpg';



class Thankyou extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <DemoNavbar />
        <main ref="main">
        <section className=" section section-lg  pb-250" style ={{backgroundImage: `url(${Background})`}}>
              {/*<div className="shape shape-style-1 shape-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>*/}

              <div ></div>
              
              <Container className="py-lg-md d-flex">
               
                <div className="col px-0">
                  <Row>
                    
                    <Col lg="6">
                      <h1 className="display-3 text-white text-uppercase">
                        Thanks for contacting me!{" "}
                        <span></span>
                      </h1>
                      <p className="lead text-white">
                        The information your provide will help me, I will reach out to you shortly!
                        Thank you again for the input.
 
                      
                      </p>
                   
                    </Col>
                  </Row>
                </div>
              </Container>
              {/* SVG separator */}

            </section>
       
          
        </main>
        <CardsFooter />
      </>
    );
  }
}

export default Thankyou;
