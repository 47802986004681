/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";

class CardsFooter extends React.Component {
  render() {
    return (
      <>
        <footer className="footer ">
      
          <Container>
            <Row className="row-grid align-items-center my-md">
              <Col lg="6">
                <h3 className="text-primary font-weight-light mb-2">
                  Looking forward to work with you!
                </h3>
                <h4 className="mb-0 font-weight-light">
                  Let's get in touch.
                </h4>
              </Col>
              <Col className="text-lg-center btn-wrapper" lg="6">

                <Button
                  className="btn-icon-only rounded-circle ml-1"
          
                  href="https://www.linkedin.com/in/youjie-tom-lin/"
                  id="tooltip829810202"
                  target="_blank"
                >
                  <span className="btn-inner--icon">
                    <i className="fa fa-linkedin" />
                  </span>
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip829810202">
                  Add me
                </UncontrolledTooltip>
                <Button
                  className="btn-icon-only rounded-circle ml-1"
                  color="github"
                  href="https://github.com/linyoujie"
                  id="tooltip495507257"
                  target="_blank"
                >
                  <span className="btn-inner--icon">
                    <i className="fa fa-github" />
                  </span>
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip495507257">
                  Star on Github
                </UncontrolledTooltip>
              </Col>
            </Row>
            <hr />
            <Row className="align-items-center justify-content-md-between">
              <Col md="6">
                <div className="copyright">
                  © {new Date().getFullYear()}{" "}
                  <a
                    href="https://www.linkedin.com/in/youjie-tom-lin/"
                    target="_blank"
                  >
                    Coded by ❤
                  </a>
                  
                </div>
              </Col>
              <Col md="6">
                <Nav className="nav-footer justify-content-end">

                <NavItem>
                    <NavLink
                      href = '/privacy-page'
                      target="_blank"
                    >
                      Product Privacy
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      href="https://www.linkedin.com/in/youjie-tom-lin/"
                      target="_blank"
                    >
                      About Author
                    </NavLink>
                  </NavItem>
    
 
                </Nav>
              </Col>
            </Row>
          </Container>
        </footer>
      </>
    );
  }
}

export default CardsFooter;
