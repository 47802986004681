/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Button, Container, Row, Col, Card, CardImg } from "reactstrap";

class Icons extends React.Component {
  render() {
    return (
      <>
        <section className="section section-lg section-nucleo-icons pb-250">
          <Container>
            <Row className="justify-content-center">
              <Col className="text-center" lg="8">
                <h2 className="display-3">React.js and Third-Party components</h2>
                <p className="lead">
                  Well designed and SEO-ed Laundry Website.
                </p>
                <div className="btn-wrapper">
             
                </div>
              </Col>
            </Row>
            <div className="blur--hover">
              <a href="https://www.87laundry.com/">
              <div class="blur-item mt-5 on-screen">
                  <Card className="bg-primary shadow border-0">
                  
                    <CardImg
                      alt="..."
                      src={require("assets/img/brand/laundryReact.png")}
                      top
                    />

                 
                  </Card>
                </div>
                <span className="blur-hidden h2 text-primary">
                  https://www.87laundry.com
                </span>
              </a>
            </div>
          </Container>
        </section>
      </>
    );
  }
}

export default Icons;
