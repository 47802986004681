/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Button, Container, Row, Col, UncontrolledCarousel } from "reactstrap";
import googleStore from '../../assets/img/brand/en_badge_web_generic.png';
import appleStore from '../../assets/img/brand/applestore.svg';
import scanQR from '../../assets/img/brand/scanQR.png'
//const googleStore =  {src: require("src/assets/imgbrand/en_badge_web_generic.png")};
//const appleStore = {src: require("assets/img/brand/applogin.png")}
const items = [
  {
    src: require("assets/img/brand/applogin.png"),
    altText: "",
    caption: "",
    header: ""
  },
  {
    src: require("assets/img/brand/appmain.png"),
    altText: "",
    caption: "",
    header: ""
  },
  {
    src: require("assets/img/brand/appprofile.png"),
    altText: "",
    caption: "",
    header: ""
  }
];

class myUsLifeAppSample extends React.Component {
  render() {
    return (
      <>
        <section className="section section-shaped">
          <div className="shape shape-style-1 shape-default">
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
          </div>
          <Container className="py-md">
            <Row className="justify-content-between align-items-center">

              <Col className="mb-5 mb-lg-0 order-lg-2" lg="6">
                <h1 className="text-white font-weight-light">
                  In-Store Application
                </h1>
                <p className="lead text-white mt-4">
                Under Covid-19 epidemic, everyone has to be isolated. In order to communicate better with everyone, this App was developed for the Chinese community. This App uses hybrid development, integrates the features like barrage and Tik-Tok's double-touches, etc. </p>
                {/* <Button
                  className="btn-white mt-4"
                  color="default"
                  href="https://demos.creative-tim.com/argon-design-system-react/#/documentation/alerts?ref=adsr-landing-page"
                >
                  See all components
                </Button> */}


              <a href="https://play.google.com/store/apps/details?id=com.myuslife.apk" style={{marginLeft: "8px"}}>

                        <img
                     
                          alt="google playstore badge with SVG"
                          className=" justify-content-between align-items-center"
                          src={googleStore}
                          height="95px" width="223px" 
                        />
                </a>
                <a href="https://apps.apple.com/us/app/id1530656253">
                 <img 
                 src={appleStore} 
                 alt="Apple Appstore badge with SVG" 
                 height="65px" width="auto" style ={{marginLeft: "22px"}}/>
                 </a>
                <div className = '' sm = '0' xs = '0' >
                 <p className="lead text-white mt-4">
                Or Scan:</p>
              
                    <img
                     
                     alt="MyUSlife APP QRcode"
                     className=" "
                     src={scanQR}
                     height="" width="150px"
                   />
              </div>
         
              </Col>
              <Col className="mb-lg-auto  order-lg-1 justify-content-between align-items-center" lg="4" md = '10' sm ='10' xs = '10'>
                <div className="rounded shadow-lg overflow-hidden transform-perspective-left">
                  <UncontrolledCarousel items={items} />
                </div>
              </Col>
            </Row>
          </Container>
          {/* SVG separator */}
          
          <div className="separator separator-bottom separator-skew">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >



              {/* <polygon className="fill-white" points="2560 0 2560 100 0 100" /> */}
            </svg>
          </div>
        </section>
      </>
    );
  }
}

export default myUsLifeAppSample;
